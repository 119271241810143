<script setup lang="ts">
const chatStore = useChatStore();
await useAsyncData("agents", () => chatStore.loadAgents());
const colorMode = useColorMode();
colorMode.preference = "light";
const isDark = computed({
  get() {
    return colorMode.value === "dark";
  },
  set() {
    colorMode.preference = colorMode.value === "dark" ? "light" : "dark";
  },
});
</script>
<template>
  <div class="bg-gray-50">
    <NuxtLayout>
      <!-- <input
        type="checkbox"
        value="synthwave"
        class="toggle theme-controller"
        @click="isDark = !isDark"
      /> -->
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
