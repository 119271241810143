import revive_payload_client_GCjPIDEb1Y from "/home/andrea/projects/abaco-chat/node_modules/.pnpm/nuxt@3.9.3_vite@5.0.12/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_zXpjm1W7xl from "/home/andrea/projects/abaco-chat/node_modules/.pnpm/nuxt@3.9.3_vite@5.0.12/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_LFsHRQRwAK from "/home/andrea/projects/abaco-chat/node_modules/.pnpm/nuxt@3.9.3_vite@5.0.12/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_T7DgBac7Ke from "/home/andrea/projects/abaco-chat/node_modules/.pnpm/nuxt@3.9.3_vite@5.0.12/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_OBKtMlssM4 from "/home/andrea/projects/abaco-chat/node_modules/.pnpm/nuxt@3.9.3_vite@5.0.12/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_Z8kE5uqUuq from "/home/andrea/projects/abaco-chat/node_modules/.pnpm/@pinia+nuxt@0.5.1_vue@3.4.15/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/andrea/projects/abaco-chat/.nuxt/components.plugin.mjs";
import prefetch_client_0Nayd4ROeZ from "/home/andrea/projects/abaco-chat/node_modules/.pnpm/nuxt@3.9.3_vite@5.0.12/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import colors_kNXDjH29RZ from "/home/andrea/projects/abaco-chat/node_modules/.pnpm/@nuxt+ui@2.12.3_nuxt@3.9.3_vite@5.0.12_vue@3.4.15/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_3dFO4wL2SD from "/home/andrea/projects/abaco-chat/node_modules/.pnpm/@nuxtjs+color-mode@3.3.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import chunk_reload_client_hT3iJ8RxCf from "/home/andrea/projects/abaco-chat/node_modules/.pnpm/nuxt@3.9.3_vite@5.0.12/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
export default [
  revive_payload_client_GCjPIDEb1Y,
  unhead_zXpjm1W7xl,
  router_LFsHRQRwAK,
  payload_client_T7DgBac7Ke,
  check_outdated_build_client_OBKtMlssM4,
  plugin_vue3_Z8kE5uqUuq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_0Nayd4ROeZ,
  colors_kNXDjH29RZ,
  plugin_client_3dFO4wL2SD,
  chunk_reload_client_hT3iJ8RxCf
]