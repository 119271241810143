export class CHAT_ROLE {
  static readonly Abby = "Abby";
  static readonly User = "User";
}

export class CHAT_KIND {
  static readonly DF = "DF";
  static readonly GPT = "GPT";
}

export type ChatMessage = {
  role: CHAT_ROLE;
  message: string;
};

export type Agent = {
  name: string;
  welcome_message: string;
  examples?: string[];
  kind?: CHAT_KIND;
};

export type ReqAgent = {
  df_agents: Agent[],
  gpt_agents: Agent[],
}